import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import Button from '../../components/button';
import { NavItem } from '../../utils/types';
import './fins-aaa.css';

export interface FinsAAAProps {
  number: string;
  style?: React.CSSProperties;
  navItems: Array<NavItem>;
  onNavItemSelected: (index: number) => void;
}

const FinsAAA: React.FC<FinsAAAProps> = ({
  number,
  style,
  navItems,
  onNavItemSelected
}) => {
  const cardImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "aaa-card@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div id="FinsAAA" style={style}>
      <div className="FinsAAA-background-text">
        <BigText
          value={number}
          thin={true}
          white={true}
          style={{ position: 'absolute', right: '-20px', top: '50px' }}
        />
      </div>
      <div className="FinsAAA-content">
        <div className="FinsAAA-title">
          <DotNav
            selected={parseInt(number, 10) - 1}
            items={navItems}
            vertical={true}
            onChange={onNavItemSelected}
            style={{ marginLeft: '-30px', position: 'absolute' }}
          />
          <div className="FinsAAA-title-content">
            <div className="FinsAAA-title-content-top">FINS &amp; AAA</div>
            <div className="FinsAAA-title-content-main">
              AAA Membership Advantages at FINS
            </div>
          </div>
        </div>
        <div className="FinsAAA-details">
          <div className="FinsAAA-details-text">
            <div className="FinsAAA__offer-details">
              Exclusive FINS Discounts <br />
              Member Appreciation Events <br />
              Fins Wash Club Savings
            </div>
            <p>
              Long known for world-class Roadside Assistance, AAA also
              offers members money-saving benefits and exclusives —
              including discounts at FINS! From discounts on insurance
              products and car care services to travel deals and savings
              at retailers and restaurants nationwide, AAA helps you get
              more for your money every day. Ready to start saving?
            </p>
            <div>
              <a href="https://join.carolinas.aaa.com/?promo=finslp1&level=basic" style={{ display: 'none' }} >
                <Button text="JOIN AAA TODAY" className="white ctaFJoin" />
              </a>
              <a href="https://www.acg.aaa.com" style={{ display: 'inline-flex' }} >
                <Button text="EXPLORE AAA MEMBERSHIP" className="white ctaFJoin" />
              </a>
              <a href="https://www.acg.aaa.com/aaa-membership/classic-basic.html" style={{ display: 'none' }}>
                <Button text="EXPLORE AAA MEMBERSHIP" className="ctaFJoin clear" />
              </a>
            </div>
          </div>
          <div className="FinsAAA-details-image-container">
            {/* <Img
              fluid={cardImage.placeholderImage.childImageSharp.fluid}
              className="FinsAAA-details-image"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinsAAA;

import { NavItem } from './types';

export default [
  { label: 'About Us', id: 'AboutUs' },
  { label: 'Promotions', id: 'Promotions' },
  { label: 'Fins & AAA', id: 'FinsAAA' },
  { label: 'Pricing', id: 'Pricing' },
  { label: 'Fins Wash Club', id: 'TopWashClub' },
  { label: 'Locations', id: 'Location' },
  { label: 'Careers', id: 'Careers' },
] as Array<NavItem>;

export const otherLinks = [{ label: 'Subscribe', id: 'Subscribe' }];

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useMedia } from '../../utils/hooks';
import VideoButton from '../../components/video-button';
import BigText from '../../components/big-text';
import './our-store.css';

export interface OurStoreProps {
  number: string;
  style?: React.CSSProperties;
}

const OurStore: React.FC<OurStoreProps> = ({ number, style }) => {
  const options = useMedia(
    ['(max-width: 770px)', '(max-width: 414px)'],
    [
      {
        number: {
          left: '-20px'
        }
      },
      {
        number: {
          left: '-20px'
        }
      }
    ],
    {
      number: {
        left: '-100px'
      }
    }
  );
  const marqueeImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "finswash-building-no-aaa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div id="OurStore" style={style}>
      <div className="OurStore-background-image-container">
        <Img
          fluid={marqueeImage.placeholderImage.childImageSharp.fluid}
          className="OurStore-background-image"
        />
      </div>
      <div className="OurStore-number">
        <BigText
          value={number}
          white={true}
          style={{
            position: 'absolute',
            top: 0,
            opacity: 0.35,
            ...options.number
          }}
        />
      </div>
      <VideoButton
        style={{ position: 'absolute', zIndex: 10, bottom: 0, right: 0 }}
        videoId="4XqljEeeIe8"
      />
    </div>
  );
};

export default OurStore;
